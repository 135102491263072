<template>
  <v-container fluid>
    <dialog-privacy-policy :dialog-true="true" style="margin-top: 100px;" />
    <dialog-offer-agreement style="margin-top: 50px;"
      >Договор на оказание услуг
    </dialog-offer-agreement>
  </v-container>
</template>

<script>
export default {
  name: 'EspeciallyForFacebook',
  components: {
    DialogPrivacyPolicy: () => import('@/components/shared/dialog/DialogPrivacyPolicy'),
    DialogOfferAgreement: () => import('@/components/shared/dialog/DialogOfferAgreement')
  }
}
</script>

<style scoped></style>
